import React from "react";
import BackButton from "../../components/BackButton";
import Footer from "../Footer";
import CommentForm from "../../components/CommentForm";
import ZoomableImage from "../../components/ZoomableImage";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./style/rebeca.scss";

const Rebeca = () => {
  return (
    <div className="body3">
      <div className="rebeca-page">
        <BackButton />

        <div className="info-container4">
          <div className="info-content4">
            <div className="info-image2">
              <img src={require("../../images/rebeca.jpg")} alt="Imagen" />
            </div>
            <div className="info-text4">
              <h1>
                "Sembrando Amor y Esperanza: La Historia Inspiradora de Rebeca
                Sánchez Sandoval"
              </h1>
              <hr />
              <h1>La Fortaleza en la Adversidad</h1>
              <p className="text-color4">
                En el corazón de la comunidad de Lebaron, se encuentra una mujer
                excepcional cuya historia es un testimonio de fortaleza y
                generosidad.
                <br />
                <br /> Rebeca Sánchez Sandoval, nacida en Atencingo, Puebla,
                enfrentó desafíos desde una edad temprana. <br />
                <br />
                La pérdida de su madre a los tres años y el abandono de su padre
                la dejaron huérfana, pero Rebeca nunca perdió la esperanza.{" "}
                <br />
                <br /> Su vida cambió cuando conoció a Ángel, su esposo, quien
                la llevó a Lebaron para comenzar una nueva vida.
                <br />
                <br /> La joven pareja emigró a Campeche, donde Rebeca persiguió
                su pasión por la enfermería. Trabajó arduamente en un hospital,
                donde aprendió habilidades cruciales para brindar atención
                médica vital, desde inyecciones hasta suturas.
                <br />
                <br /> Lo que distingue a Rebeca es su generosidad
                desinteresada. <br />
                <br />A pesar de sus habilidades y conocimientos, nunca cobró
                por sus servicios. <br />
                <br />
                Su amor por ayudar a los demás superó cualquier consideración
                económica. La comunidad de Lebaron la recuerda con gratitud y
                cariño, y su partida de Campeche conmovió a muchas personas.{" "}
                <br />
                <br />
                Rebeca es madre de 17 hijos, de los cuales 12 aún viven. Su
                dedicación a la familia es palpable, y en su tiempo libre,
                disfruta coser y cuidar su hogar. <br />
                <br /> A sus 80 años, su espíritu sigue siendo fuerte, y su
                pasión por la enfermería y ayudar a otros sigue siendo evidente.{" "}
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
        {/* 
        <div>
          <div className="four-column-container3">
            <div className="column3">
              <ZoomableImage
                src={require("../../images/rebeca.jpg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>
            <div className="column3">
              <ZoomableImage
                src={require("../../images/rebeca.jpg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>{" "}
            <div className="column3">
              <ZoomableImage
                src={require("../../images/rebeca.jpg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>{" "}
            <div className="column3">
              <ZoomableImage
                src={require("../../images/rebeca.jpg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>
          </div>
        </div> */}

        <div className="info-container4">
          <div className="info-content4">
            <div className="info-text4">
              <div className="color4">
                <h1>Sembrando el Bien en Lebaron</h1>
                <hr />
              </div>
              <p className="text-color3">
                Rebeca Sánchez Sandoval es más que una enfermera retirada; es un
                pilar de la comunidad de Lebaron. <br />
                <br /> A pesar de los cambios y desafíos que ha enfrentado a lo
                largo de los años, Rebeca ha mantenido una actitud positiva y
                una dedicación inquebrantable a la comunidad que ahora considera
                su hogar.
                <br />
                <br /> Recuerda con cariño cómo el hermano Joel, uno de los
                líderes de la comunidad, les instó a "portarse bien y sembrar el
                bien" cuando llegaron a Lebaron. <br />
                <br />
                Estas palabras resonaron en su corazón y guiaron su vida. Rebeca
                ha sido un ejemplo viviente de hacer el bien sin esperar nada a
                cambio. <br />
                <br /> A pesar de su propio camino difícil, Rebeca ha brindado
                su amor y atención a innumerables personas en Lebaron a lo largo
                de los años.
                <br />
                <br /> Su legado es el de una mujer que ha dejado una huella
                imborrable en la comunidad y ha inspirado a otros a seguir sus
                pasos. <br />
                <br /> En sus propias palabras, Rebeca ofrece un consejo
                valioso: "Siempre hagan el bien y pidan a Dios que les dé
                fuerza".
                <br />
                <br /> Su vida es un recordatorio de que, incluso en las
                circunstancias más difíciles, una persona puede marcar una
                diferencia positiva y sembrar el bien en su comunidad. <br />
                <br />
                Hoy, brindamos por Rebeca Sánchez Sandoval, una mujer
                extraordinaria cuyo amor y servicio han enriquecido la vida de
                todos los que han tenido el privilegio de conocerla.
                <br />
                <br /> Su historia es un faro de luz y esperanza en la comunidad
                de Lebaron y más allá.
              </p>
            </div>
            <div className="info-image2">
              <img src={require("../../images/rebeca.jpg")} alt="Imagen" />
            </div>
          </div>
        </div>

        <div className="image3">
          <img src={require("../../images/n28.png")} alt="Imagen" />
        </div>
        {/* <CommentForm /> */}
        <Link to="/brindis">
          <FontAwesomeIcon
            className="icon-login"
            icon={faLeftLong}
            style={{ fontSize: "3em" }}
          />
        </Link>
        <Footer />
      </div>
    </div>
  );
};

export default Rebeca;
