import React from "react";
import BackButton from "../../components/BackButton";
import Footer from "../Footer";
import CommentForm from "../../components/CommentForm";
import ZoomableImage from "../../components/ZoomableImage";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./style/elsie.scss";

const Elsie = () => {
  return (
    <div className="body5">
      <div className="elsie-page">
        <BackButton />

        <div className="info-container6">
          <div className="info-content6">
            <div className="info-image2">
              <img src={require("../../images/elsie.jpeg")} alt="Imagen" />
            </div>
            <div className="info-text6">
              <h1>"Elsie Liddiard: Inspirando Cambio a Través del Servicio"</h1>
              <hr />
              <h1> Un Legado de Amor y Servicio</h1>
              <p className="text-color6">
                En la apacible Colonia Lebaron, nacía Elsie Liddiard, hija de
                arraigada en la comunidad.
                <br />
                Tomas Liddiard y Linda. Su padre, un ingeniero e historiador
                local, y su madre, una partera dedicada, formaron una familia
                arraigada en la comunidad.
                <br />
                <br /> Desde temprana edad, Elsie tenía un sueño: seguir los
                pasos de su madre y convertirse en partera. Esta vocación
                temprana sentaría las bases de su vida de servicio y amor por la
                vida. <br />
                <br />
                Elsie siempre supo que quería ser partera, como su madre. Sin
                embargo, su padre le enseñó la importancia de buscar la verdad
                incansablemente.
                <br />
                <br /> Esta enseñanza se convirtió en arraigada en la comunidad.
                <br />
                uno de los pilares de su vida. La pasión de Elsie reside en la
                libertad. En un mundo que a menudo olvida este valor
                fundamental, Elsie se siente llamada a preservarlo y compartilo.
                <br />
                <br />
                A lo largo de los años, ha visto cómo su comunidad y su familia
                comparten los mismos valores, a pesar de las diferencias
                religiosas. <br />
                <br />
                Esto la une aún más a su entorno y fortalece su compromiso de
                superar las divisiones sociales.
                <br />
                <br /> La influencia de su madre sigue siendo profunda en su
                vida.
                <br /> Linda, su madre, sigue enseñando y orientando, incluso en
                su vejez.
                <br /> Además de su madre, Elsie encuentra inspiración en
                Jesucristo, quien sacrificó mucho por la humanidad.
                <br />
                <br /> Elsie, madre de cinco hijos, valora sobre todo a su
                familia.
                <br />
                <br /> Disfruta de su tiempo libre junto a ellos, demostrando su
                amor inquebrantable por su hogar. Su compromiso en la vida es
                claro: ella busca dejar un legado de unidad y superar las
                divisiones sociales. <br />
                <br />
              </p>
            </div>
          </div>
        </div>
        {/* <div>
          <div className="four-column-container5">
            <div className="column5">
              <ZoomableImage
                src={require("../../images/elsie.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>
            <div className="column5">
              <ZoomableImage
                src={require("../../images/elsie.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>{" "}
            <div className="column5">
              <ZoomableImage
                src={require("../../images/elsie.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>{" "}
            <div className="column5">
              <ZoomableImage
                src={require("../../images/elsie.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>
          </div>
        </div> */}
        <div className="info-container6">
          <div className="info-content6">
            <div className="info-text6">
              <div className="color4">
                <h1> La Pasión de Elsie por el Servicio</h1>
                <hr />
              </div>
              <p className="text-color3">
                La labor más importante de Elsie ha sido ayudar a su esposo a
                crecer y alcanzar sus metas.
                <br /> Su trabajo conjunto ha llevado a numerosos logros, y
                Elsie siente un profundo orgullo al ver el crecimiento personal
                de su esposo.
                <br />
                <br /> Juntos, han construido un sólido cimiento para su
                familia.
                <br />
                <br /> A pesar de su compromiso y dedicación al trabajo, Elsie
                valora su tiempo en casa con sus hijos. <br />
                <br />
                Encuentra el equilibrio en enfocarse en su familia una vez que
                regresa a casa.
                <br /> Esta armonía entre sus roles de madre y mujer trabajadora
                es un testimonio de su habilidad para equilibrar la vida. <br />
                <br />
                Elsie se destaca por su inquebrantable espíritu de servicio.
                <br /> Siempre está dispuesta a ayudar y brindar amor a su
                comunidad.
                <br />
                <br /> Su motivación radica en el amor que siente por su
                comunidad y en su firme creencia de que podemos mejorar este
                mundo juntos. <br />
                <br />
                Su vida está marcada por una búsqueda constante de la verdad, un
                profundo amor por la libertad y un compromiso inquebrantable con
                su familia y comunidad.
                <br />
                <br /> A través de su dedicación y pasión por el servicio, Elsie
                Liddiard se ha convertido en un faro de esperanza en un mundo
                que a menudo necesita un recordatorio de los valores esenciales
                de la vida: el amor, la unidad y la libertad.
                <br />
                <br /> Su historia es un testimonio de cómo una vida dedicada al
                servicio y al amor puede tener un impacto duradero en una
                comunidad y en el mundo en general.
                <br />
                <br />
                Elsie Liddiard es una inspiración para todos nosotros, un
                recordatorio de que podemos marcar la diferencia cuando buscamos
                la verdad, valoramos la libertad y, sobre todo, amamos y
                servimos a los demás.
              </p>
            </div>
            <div className="info-image2">
              <img src={require("../../images/elsie.jpeg")} alt="Imagen" />
            </div>
          </div>
        </div>

        <div className="image4">
          <img src={require("../../images/n43.png")} alt="Imagen" />
        </div>
        {/* <CommentForm /> */}
        <Link to="/brindis">
          <FontAwesomeIcon
            className="icon-login"
            icon={faLeftLong}
            style={{ fontSize: "3em" }}
          />
        </Link>
        <Footer />
      </div>
    </div>
  );
};

export default Elsie;
