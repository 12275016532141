import React, { useState } from "react";
import "../styles/navbar.scss";
import Logo from "./Logo";
import BackgroundVideo from "./BackgroundVideo";
import VideoSource from "../images/copalebaron5.mp4";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "./auth/AuthContext";

const Navbar = () => {
  const { token, setToken } = useAuth();

  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleLogOut = () => {
    setToken("");
    navigate("/login");
    localStorage.removeItem('token')
  };

  return (
    <nav className={`navbar ${menuOpen ? "open" : ""}`}>
      <BackgroundVideo videoSource={VideoSource} />
      <Logo />

      <div
        className={`menu-toggle ${menuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        {menuOpen ? (
          <div className="close-icon" onClick={closeMenu}>
            &times;
          </div>
        ) : (
          <>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </>
        )}
      </div>

      <ul className={`nav-links ${menuOpen ? "active" : ""}`}>
        <li>
          <Link to="/content">Inicio</Link>
        </li>
        <li>
          <Link to="/about">Acerca de</Link>
        </li>
        <li>
          <Link to="/brindis">Brindis</Link>
        </li>
        <li>
          {token ? (
            <button onClick={handleLogOut} className="auth-button">
              Cerrar sesión
              <FontAwesomeIcon
                className="icon-login2"
                icon={faRightToBracket}
              />
            </button>
          ) : (
            <Link to="/login">
              <button className="auth-button">
                Iniciar sesión
              </button>
            </Link>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
