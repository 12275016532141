import React from "react";
import Navbar from "./Navbar";
import InfoPage from "../pages/InfoPage";
import Footer from "../pages/Footer";
import CommentForm from "./CommentForm";
import { useAuth } from "./auth/AuthContext";
import { Link } from "react-router-dom";
export default function Content() {
  const { token } = useAuth();

  return (
    <div className="App">
      <Navbar />

      <InfoPage />

      {/* <div className="container"></div>
      {token ? (
        <CommentForm />
      ) : (
        <Link to="/login">
          <button className="login-button">
            Inicia sesión para dejar tu brindis
          </button>
        </Link>
      )} */}
      <div>
        <Footer />
      </div>
    </div>
  );
}
