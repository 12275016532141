import React from "react";
import { useState } from "react";

const LoginViewModel = () => {
  const [values, setValues] = useState({
    userName: "",
    password: "",
  });

  const onChange = (property, value) => {
    setValues({ ...values, [property]: value });
  };

  return {
    ...values,
    onChange,
  };
};

export default LoginViewModel;
