import React from "react";
import "../styles/footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
// import { faFacebook } from "@fortawesome/free-brands-svg-icons";
// import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <a
          href="https://api.whatsapp.com/send?phone=526361110307"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faWhatsapp} size="2x" color="#00ff00" />
        </a>
        {/* <a
          href="https://www.facebook.com/TUFACEBOOK"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} size="2x" color="#1778F2" />
        </a>
        <a
          href="https://www.facebook.com/TUFACEBOOK"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} size="2x" color="orange" />
        </a> */}
      </div>
      <div className="footer-text">
        <h4 className="footer-end">
          © BRINDO POR | ALL RIGHTS RESERVED WHISTLE CODING CREATED BY M.
          BELMONT.
          {new Date().getFullYear()}
        </h4>
      </div>
    </footer>
  );
};

export default Footer;
