import React from "react";
import { useState } from "react";
import "../styles/infopage.scss";
import BounceButton from "../components/BounceButton";
import ZoomableImage from "../components/ZoomableImage";

const InfoPage = () => {
  const [showComplementaryText, setShowComplementaryText] = useState(false);

  const toggleComplementaryText = () => {
    setShowComplementaryText(!showComplementaryText);
  };

  return (
    <div className="info-container">
      <div className="info-content">
        <div className="info-image">
          <ZoomableImage src={require("../images/martha3.png")} alt="Imagen" />
        </div>
        <div className="info-text">
          <h1>"BRINDA CON NOSOTROS"</h1>
          <hr />
          <p>
            "Sumérgete en la Historia Profunda de LeBaron: Explora las Vidas,
            Familias y Legados de Personajes Célebres, así como sus Invaluables
            Contribuciones a la Comunidad. Cada semana habrá una célebre
            persona, entérate! <br />
            <br />
            <div className="text-color">
              "La admirable historia de Martha Guadalupe Muñoz Hernández: Un
              legado de inspiración y fortaleza"
            </div>
          </p>
          {showComplementaryText ? (
            <p>
              En el corazón de LeBaron, un alma brillante y excepcional dejó una
              huella imborrable en la comunidad: Martha Guadalupe Muñoz
              Hernández. <br />
              <br />
              Desde temprana edad, su valentía y dulzura la definieron. Su
              historia de amor con Adrián LeBarón creció junto a su familia de
              11 hijos, floreciendo en la calidez de su hogar.
              <br />
              <br /> A pesar de adversidades, la fortaleza y unidad de la
              familia fueron su sostén en los momentos oscuros.
              <br />
              <br /> Martha también se entregó a la educación en la escuela Alma
              Dayer, donde dejó su amor y dedicación durante 18 años como
              directora.
              <br />
              <br /> Su legado es una mezcla de amor, esperanza y entrega a la
              comunidad.
              <br />
              <BounceButton />
              <br />
            </p>
          ) : null}
          <div className="leer-mas">
            <button onClick={toggleComplementaryText}>
              {showComplementaryText ? "Leer menos" : "Leer más"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPage;
