import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

//PrivateRoute
import UserRoute from "./components/PrivateRoute/UserRoute";

import React from "react";
import About from "./pages/About";
import Home from "./pages/Home";
import Content from "./components/Content";
import Brindis from "./pages/Brindis";
import BackButton from "./components/BackButton";

import LoginPage from "../src/pages/authentication/Login/Login";

//brindis user
import Martha from "./pages/blogs/martha";
import Ricardo from "./pages/blogs/ricardo";
import Rebeca from "./pages/blogs/rebeca";
import Adela from "./pages/blogs/adela";
import Elsie from "./pages/blogs/elsie";
import Merie from "./pages/blogs/merie";
import Raymundo from "./pages/blogs/raymundo";
import Emily from "./pages/blogs/emily";
import Beberly from "./pages/blogs/beberly9";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Private Routes */}
          <Route element={<UserRoute />}>
            {/* Brindis */}
            <Route exact path="/martha" element={<Martha />} />
            <Route exact path="/ricardo" element={<Ricardo />} />
            <Route exact path="/rebeca" element={<Rebeca />} />
            <Route exact path="/adela" element={<Adela />} />
            <Route exact path="/elsie" element={<Elsie />} />
            <Route exact path="/merie" element={<Merie />} />
            <Route exact path="/raymundo" element={<Raymundo />} />
            <Route exact path="/emily" element={<Emily />} />
            <Route exact path="/beberly9" element={<Beberly />} />
          </Route>

          {/* Public Route */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/content" element={<Content />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/brindis" element={<Brindis />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
