import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const UserRoute = () => {
  const localStorageToken = localStorage.getItem('token')
  const isUserToken = localStorageToken;

  return isUserToken ? <Outlet /> : <Navigate to="/login" />;
};

export default UserRoute;
