import React from "react";
import BackButton from "../../components/BackButton";
import Footer from "../Footer";
import CommentForm from "../../components/CommentForm";
import ZoomableImage from "../../components/ZoomableImage";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./style/emily.scss";

const Emily = () => {
  return (
    <div className="body8">
      <div className="emily-page">
        <BackButton />

        <div className="info-container9">
          <div className="info-content9">
            <div className="info-image2">
              <ZoomableImage
                src={require("../../images/emily.jpeg")}
                alt="Imagen"
              />
            </div>
            <div className="info-text9">
              <h1>
                "Sigue Tu Corazón: El Camino de Emily hacia la Inspiración"
              </h1>
              <hr />
              <h1> "El Amor y la Fe en Acción"</h1>
              <p className="text-color9">
                Una mujer que es un faro de inspiración para los jóvenes de
                nuestra comunidad. <br />
                <br />
                Emily Natasha Lebaron Ray, su nombre es sinónimo de amor,
                dedicación y servicio. Los padres de Emily, Joel Lebaron Soto y
                Ramona Ray, la criaron en un hogar lleno de amor y cariño.
                <br />
                <br />
                Emily recuerda con cariño su infancia, una época hermosa en la
                que su madre siempre estaba presente. A pesar de tener una
                familia numerosa, Emily siempre sintió el afecto y la atención
                de su madre. <br /> <br /> Su padre, aunque tenía muchas
                responsabilidades debido a la extensa familia que tenían,
                siempre procuraba demostrarles su cariño y los llevaba a menudo
                al rancho, creando recuerdos inolvidables. <br />
                <br />
                Desde muy pequeña, Emily soñaba con ser maestra y su modelo a
                seguir siempre fue su madre. <br />
                <br />
                Durante su adolescencia, Emily experimentó altibajos
                emocionales. Una separación de sus amigos en la secundaria la
                hizo sufrir, pero fue el baloncesto lo que la ayudó a superar
                esos obstáculos. Sin embargo, la vida le tenía más desafíos
                preparados. <br />
                <br />
                La etapa de preparatoria la llevó por un camino en el que se
                distrajo de sus principios, influenciada por amigos que la
                alejaron de lo que realmente importaba. Pero la vida tenía más
                lecciones para Emily. <br /> <br /> Sus amigos sufrieron un
                accidente que la hizo sufrir demasiado. A pesar de estos
                momentos difíciles, Emily encontró el amor y se casó a la
                temprana edad de 16 años. <br />
                <br />
                Hoy en día, es madre de ocho hijos, cinco hombres y tres
                mujeres, con cuatro pequeños angelitos en el cielo que la miran
                con amor. <br />
                <br /> Emily considera que su mayor bendición es su familia, que
                la respalda y la llena de amor. Su esposo es su roca, y su
                esperanza más grande es estar junto a él y sus hijos en el cielo
                por la eternidad, una creencia que fundamenta en su profunda fe
                religiosa.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>

        {/* <div>
          <div className="four-column-container8">
            <div className="column8">
              <ZoomableImage
                src={require("../../images/emily.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>
            <div className="column8">
              <ZoomableImage
                src={require("../../images/emily.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>{" "}
            <div className="column8">
              <ZoomableImage
                src={require("../../images/emily.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color7">LOREM</p>
            </div>{" "}
            <div className="column8">
              <ZoomableImage
                src={require("../../images/emily.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color7">LOREM</p>
            </div>
          </div>
        </div> */}

        <div className="info-container9">
          <div className="info-content9">
            <div className="info-text9">
              <div className="color4">
                <h1>"Deja Tu Huella en el Mundo"</h1>
                <hr />
              </div>
              <p className="text-color3">
                Emily Lebaron Ray es una inspiración viviente en nuestra
                comunidad. <br />
                <br />
                Su pasión por dejar un mundo mejor de lo que lo encontró es
                contagiosa. Emily cree firmemente en el poder de la
                autodeterminación y en cómo puede cambiar para mejor nuestra
                comunidad.
                <br />
                <br />
                Lo que más apasiona a Emily es el servicio a la juventud. Sabe
                que son el futuro y quiere que sepan que tienen a alguien en
                quien pueden confiar.
                <br />
                <br /> A menudo, la vida puede ser un desafío, pero Emily está
                allí para recordarles que siempre hay una luz al final del
                túnel. <br />
                Su consejo para todos es simple pero poderoso: "Vive la vida con
                integridad".
                <br /> <br />
                Emily cree que tomar decisiones que estén alineadas con nuestros
                corazones nos libera del arrepentimiento, haciendo que la vida
                valga la pena vivirla. Su mayor fuente de inspiración son sus
                hijos, quienes la esperan en casa.
                <br />
                <br /> Cuando enfrenta momentos difíciles, se levanta sabiendo
                que ellos la necesitan y la miran con admiración.
                <br />
                <br /> Quise entrevistar a Emily porque su vida es un testimonio
                de servicio y amor.
                <br /> <br />
                Como ella misma dice, "cuando das y estás en servicio de tu
                prójimo, estás en servicio de tu Dios".
                <br />
                <br /> Lo hace con un corazón lleno de amor y compasión. Su
                consejo final es vivir la vida con integridad y tomar decisiones
                que no causen arrepentimiento.
              </p>
            </div>
            <div className="info-image2">
              <ZoomableImage
                src={require("../../images/emily.jpeg")}
                alt="Imagen"
              />
            </div>
          </div>
        </div>

        <div className="image4">
          <img src={require("../../images/n61.png")} alt="Imagen" />
        </div>
        {/* <CommentForm /> */}
        <Link to="/brindis">
          <FontAwesomeIcon
            className="icon-login"
            icon={faLeftLong}
            style={{ fontSize: "3em" }}
          />
        </Link>
        <Footer />
      </div>
    </div>
  );
};

export default Emily;
