import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Login.scss";
import useViewModel from "./ViewModel";
import { useAuth } from "../../../components/auth/AuthContext";
import scrollToTop from "../../../hooks/scrollToTop";
import Navbar from "../../../components/Navbar";
import champagne from '../../../images/champagne-glass.png'
const LoginPage = () => {
  const { setToken, isAuthenticated } = useAuth();
  const { userName, password, onChange } = useViewModel();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    scrollToTop()
  });

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const handleLogin = async () => {
    try {
      const response = await axios.post(`https://lebarongaleana-api.com/api/login/`, {
        username: userName,
        password: password,
      });

      // Assuming the token is returned in the response
      const token = response.data.token;
      console.log(token);

      // Store the token in context
      setToken(token);
      localStorage.setItem('token', token)
      // navigate("/");
    } catch (error) {
      console.error("Login error:", error);
      setErrorMessage('Incorrect credentials');
    }
  };

  return (
    <>
      <Navbar />
      <div className="formWrapper">
        <div className="prettyColumn">
          <div className="prettyColumnChild">
            <img className="champagneImage" src={champagne} alt="champagne" />
            <span className="prettyColumnTitle">Bienvenido a Brindo Por</span>
          </div>
          <div className="registerText">
            Si no tienes cuenta, regístrate <a href="https://lebarongaleana.com/registration" target="_blank" rel="noreferrer">aquí</a>
          </div>
        </div>
        <div className="loginForm">
          <span className="loginTitle">
            Ingresa con tu cuenta de lebarongaleana.com
          </span>
          <div className="inputsContainer">
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <span style={{ color: 'white' }}>
              Username
            </span>
            <input
              className="loginInput"
              type="text"
              placeholder="username"
              value={userName}
              onChange={(e) => onChange("userName", e.target.value)}
            />
            <span style={{ color: 'white' }}>
              Password
            </span>
            <input
              className="loginInput"
              type="password"
              placeholder="password"
              value={password}
              onChange={(e) => onChange("password", e.target.value)}
            />
            <button className="loginButton" onClick={handleLogin}>
              Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
