import React from "react";
import BackButton from "../../components/BackButton";
import Footer from "../Footer";
import CommentForm from "../../components/CommentForm";
import ZoomableImage from "../../components/ZoomableImage";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./style/ricardo.scss";

const Ricardo = () => {
  return (
    <div className="body2">
      <div className="ricardo-page">
        <BackButton />

        <div className="info-container3">
          <div className="info-content3">
            <div className="info-image2">
              <ZoomableImage
                src={require("../../images/ricardo.jpeg")}
                alt="Imagen"
              />
            </div>
            <div className="info-text3">
              <h1>
                "Ricardo Paisano: Acompañando a Colonia Lebaron con su Melodía
                de Vida"
              </h1>
              <hr />
              <h1> Un Comienzo enraizado en el Trabajo Duro</h1>
              <p className="text-color3">
                En el corazón de Colonia Lebaron, un hombre excepcional, Ricardo
                Paisano, se alzaba como un faro de inspiración. <br />
                <br />
                Su historia es un tributo al trabajo arduo y la pasión que puede
                enriquecer una comunidad.
                <br />
                <br /> Ricardo proviene de un hogar donde los valores del
                esfuerzo y la dedicación eran la norma. Sus padres, Amelia
                Torres y Moroni Paisano, representaban la esencia de trabajar
                incansablemente para construir un futuro mejor. <br />
                <br />
                Su padre, con habilidades en la construcción, y su madre, una
                ama de casa que más tarde establecería un restaurante en su
                propio hogar durante dos décadas, forjaron un ambiente donde el
                trabajo y la familia se entrelazaban. <br />
                <br />
                Ricardo pasó sus primeros años con sus abuelos en Casas Grandes,
                una experiencia que le otorgó una perspectiva única de la vida.
                <br />
                <br /> A los seis años, regresó a vivir con sus padres y, aunque
                la transición fue diferente, descubrió una pasión que lo
                acompañaría toda su vida: la música. <br />
                <br />
                Su inspiración provenía de los grupos musicales locales, como
                Ritmo Cinco, donde un joven Iván Johnson se convirtió en su
                ídolo.
                <br />
              </p>
            </div>
          </div>
        </div>

        {/* <div>
          <div className="four-column-container2">
            <div className="column2">
              <ZoomableImage
                src={require("../../images/ricardo1.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>
            <div className="column2">
              <ZoomableImage
                src={require("../../images/ricardo1.jpeg")}
                alt="Imagen 2"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>
            <div className="column2">
              <ZoomableImage
                src={require("../../images/ricardo1.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>
            <div className="column2">

              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>
          </div>
        </div> */}

        <div className="info-container3">
          <div className="info-content3">
            <div className="info-text3">
              <div className="color4">
                <h1>"La Música como Vida y Comunidad"</h1>
                <hr />
              </div>
              <p className="text-color3">
                La música se convirtió en el núcleo de la vida de Ricardo.
                Durante su juventud, soñaba con seguir los pasos de Iván Johnson
                y sus compañeros de banda.
                <br />
                <br /> Quería ser como ellos, activo y comprometido en la
                comunidad de Colonia Lebaron. Ricardo no solo persiguió su
                pasión, sino que también mantuvo viva la llama de la música,
                incluso después de conocer a los hijos y esposas de sus ídolos
                musicales. <br />
                <br /> La adolescencia de Ricardo lo llevó a Estados Unidos,
                donde trabajó junto a sus hermanos.
                <br />
                <br /> Si bien podía haberse desviado por el camino equivocado,
                mantuvo su enfoque en vivir su propia adolescencia sin sucumbir
                a las influencias negativas. <br />
                <br />
                Se convirtió en el protector de su familia, cuidando de sus
                primas y evitando las malas compañías. La música y la pasión por
                las bocinas se convirtieron en su refugio. Pasaba días enteros
                moviendo cables y ajustando equipos de sonido.
                <br />
                <br /> Esta pasión, heredada de su familia, seguía fluyendo en
                su sangre. Sin embargo, lo más valioso en la vida de Ricardo es
                su familia. Su esposa e hijos son su razón de ser. Juntos,
                trabajan como socios en la vida y en el negocio familiar. <br />
                <br />
                Esta unión es lo que realmente da sentido a su existencia.{" "}
                <br />
                <br /> Ricardo es un defensor de la comunidad y cree que cada
                individuo tiene algo valioso que aportar. <br />
                <br />
                En el 2023, observa con gratitud cómo la comunidad de Lebaron se
                une, reconociendo la importancia de cada persona y su capacidad
                para contribuir al bienestar común. <br />
                <br />
                Ricardo Paisano personifica la perseverancia, la pasión y la
                dedicación.
                <br />
                <br /> Su historia es un recordatorio de que, a través de la
                música y el compromiso con la comunidad, uno puede enriquecer la
                vida de todos a su alrededor.
                <br />
              </p>
            </div>
            <div className="info-image2">
              <ZoomableImage
                src={require("../../images/ricardo1.jpeg")}
                alt="Imagen"
              />
            </div>
          </div>
        </div>

        <div className="image3">
          <img src={require("../../images/n24.png")} alt="Imagen" />
        </div>

        {/* <CommentForm /> */}
        <Link to="/brindis">
          <FontAwesomeIcon
            className="icon-login"
            icon={faLeftLong}
            style={{ fontSize: "3em" }}
          />
        </Link>
        <Footer />
      </div>
    </div>
  );
};

export default Ricardo;
