import React from "react";
import ReactPlayer from "react-player";
import BackButton from "../../components/BackButton";
import Footer from "../Footer";
import CommentForm from "../../components/CommentForm";
import ZoomableImage from "../../components/ZoomableImage";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./style/martha.scss";

const Martha = () => {
  return (
    <div className="martha-page">
      <BackButton />

      <div className="info-container2">
        <div className="info-content2">
          <div className="info-image2">
            <ZoomableImage
              src={require("../../images/martha.jpeg")}
              alt="Imagen"
            />
          </div>
          <div className="info-text2">
            <h1>
              "La admirable historia de Martha Guadalupe Muñoz Hernández: Un
              legado de inspiración y fortaleza"
            </h1>
            <hr />

            <p className="text-color2">
              En el corazón del encantador pueblo de LeBaron, entre las montañas
              y los valles que enmarcan su belleza, se encuentra un alma
              excepcional, una luz brillante que ha dejado una huella imborrable
              en la comunidad: Martha Guadalupe Muñoz Hernández. <br />
              <br />
              Su historia es un canto a la vida, a la perseverancia y al amor
              inquebrantable que trasciende todas las adversidades. <br />
              <br />
              El 17 de noviembre de 1959, el mundo recibió el regalo de la
              llegada de Martha Guadalupe. Desde temprana edad, sus ojos
              irradiaban una chispa especial, una mezcla de valentía y dulzura
              que se convertiría en su sello distintivo en los años venideros.{" "}
              <br />
              <br /> Su vida comenzó a tejerse en los rincones de Chihuahua,
              donde cada risa, cada lágrima y cada paso forjaron el camino que
              estaba destinada a recorrer. <br />
              <br /> En un giro del destino, el amor la condujo a los brazos de
              Adrián LeBarón, un hombre con quien compartiría su existencia y
              construiría una familia sólida y amorosa. <br />
              <br /> Su historia de amor floreció en el entorno de una empresa
              en la que ambos trabajaban, y juntos enfrentaron los desafíos de
              la vida, tomados de la mano para superar cualquier obstáculo que
              se cruzara en su camino.
              <br />
              <br /> En 1979, Martha llegó a LeBaron y, con ello, plantó las
              raíces de su hogar en esta tierra acogedora. <br />
              <br /> El destino les concedió el inmenso regalo de la paternidad,
              y la familia LeBaron floreció con la llegada de 11 hijos, seis
              varones y cinco preciosas mujeres. Pero la vida, a veces
              caprichosa, les desgarró el alma con la partida prematura de dos
              de sus amados hijos. <br />
              <br /> Sin embargo, la fortaleza y la unidad de la familia los
              sostuvieron en los momentos más oscuros.
              <br />
              <br /> El hogar de los LeBarón se convirtió en un refugio de amor
              y esperanza, y fue también el lugar donde la vida encontró su
              inicio para muchos de sus hijos. <br />
              <br /> Martha, con el apoyo de una partera de la comunidad,
              recibió a la mayoría de sus pequeños en la calidez del hogar
              familiar. <br />
              <br />
              Esas experiencias llenas de ternura y conexión íntima dejaron
              recuerdos imborrables, momentos de magia y asombro que ella
              atesora en lo más profundo de su corazón.
              <br />
              <br /> Pero la vida de Martha no solo se tejió en el entorno
              familiar, sino que su espíritu altruista y dedicado la llevó a
              servir a su comunidad en la escuela Alma Dayer durante 18 años.{" "}
              <br />
              <br /> Como directora, entregó su corazón y su alma para
              asegurarse de que cada estudiante recibiera una educación
              enriquecedora y de calidad.
              <br />
              <br /> Para sus hijos, fue un orgullo inmenso ver cómo la figura
              materna y la figura educativa se entrelazan, sabiendo que cada
              certificado que firmaba llevaba consigo el amor y el esfuerzo de
              una madre inigualable.
              <br />
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="four-column-container">
          <div className="column">
            <ZoomableImage
              src={require("../../images/mya.jpeg")}
              alt="Imagen 1"
            />
            <h2>MARTHA & ADRIAN</h2>
            <p className="color">
              "Unidos por el amor, forjando un legado eterno."
            </p>
          </div>
          <div className="column">
            <ZoomableImage
              src={require("../../images/familia.jpeg")}
              alt="Imagen 2"
            />
            <h2>FAMILIA</h2>
            <p className="color">
              "Unidos en un abrazo eterno, nuestra familia, nuestro tesoro."
            </p>
          </div>
          <div className="column">
            <ZoomableImage
              src={require("../../images/boda.jpeg")}
              alt="Imagen 1"
            />
            <h2>"Celebrando juntos, logros inolvidables."</h2>
            <p className="color">
              Un amor eterno: Celebrando la unión que siempre soñamos.
            </p>
          </div>
          <div className="column">
            <ZoomableImage
              src={require("../../images/hija.jpeg")}
              alt="Imagen 1"
            />
            <h2>Madre e hijas</h2>
            <p className="color">
              "Abuela y nieta, unidas por siempre en el corazón de LeBaron."
            </p>
          </div>
        </div>
      </div>
      <div className="info-text2">
        <div className="color2">
          <h1>"Unidos en LeBaron, donde el amor teje nuestra fortaleza."</h1>
          <hr />
        </div>
        <p className="color">
          En la vida de Martha Guadalupe Muñoz Hernández, hay un capítulo que
          destaca como un remanso de solidaridad y amor incondicional.
          <br />
          <br /> En un momento difícil, cuando la adversidad económica parecía
          ahogar las esperanzas, su hijo Adrián Dan LeBarón enfrentó un reto que
          sacudió los cimientos de su familia. <br />
          <br />
          <br />
          Sin embargo, la unión y el apoyo inquebrantable de Martha y su familia
          se convirtieron en el faro de luz que les guió en la oscuridad.
          <br />
          <br /> Con valentía y determinación, todos se unieron, dándose fuerzas
          unos a otros para superar los obstáculos que la vida les había
          presentado. <br />
          La comunidad de LeBaron, siempre solidaria y cálida, se unió en un
          abrazo de amor, brindando ayuda y esperanza en cada paso del camino.
          <br />
          <br /> Gracias a la unión familiar y a la generosidad de su entorno,
          las cosas comenzaron a fluir y poco a poco, la adversidad se convirtió
          en oportunidad. <br />
          Con gratitud en el corazón y el espíritu resiliente, Martha y su
          familia pudieron superar la tormenta y recuperarse, reconstruyendo sus
          vidas con amor y determinación.
          <br />
          <br /> El destino les llevó por caminos inesperados, pero cada giro
          los condujo de regreso a LeBaron, donde actualmente han construido un
          hogar, un refugio de amor y alegría hecho por las manos de sus
          grandiosos hijos.
          <br /> En esta comunidad cálida y solidaria, Martha encuentra su hogar
          verdadero.
          <br />
          <br /> Aquí, el apoyo y la fraternidad fluyen como ríos, sosteniendo a
          cada persona en momentos de necesidad.
          <br /> En cada esquina, hay una mano dispuesta a tenderse y un corazón
          dispuesto a comprender.
          <br />
          <br /> Cuando la vida presentó otro desafío, esta comunidad amorosa
          respondió con la misma fuerza y compasión.
          <br /> Un accidente marcó profundamente la vida de Martha y su hija,
          Glena Yisela LeBaron Muñoz, llevándolas a enfrentar una difícil prueba
          en el hospital.
          <br />
          <br /> Pero una vez más, la solidaridad y el cariño de LeBaron se
          hicieron sentir, sosteniéndose en cada paso de su recuperación. <br />
          Martha se siente bendecida y agradecida por vivir en un lugar como
          LeBaron, donde la bondad y la empatía son moneda corriente. <br />
          <br />
          Cada gesto de apoyo, cada palabra de aliento, dejaron una huella
          imborrable en su corazón, demostrándole que no hay mejor lugar para
          vivir que aquí.
          <br />
          <br /> Y así, con el corazón lleno de gratitud y la convicción de que
          el amor y la unidad son el motor que impulsa a esta comunidad, Martha
          da las gracias públicamente.
          <br /> Agradece por el amor incondicional, por el apoyo en los
          momentos más difíciles y por el regalo invaluable de pertenecer a esta
          familia extendida que es LeBaron.
          <br />
          <br /> En este rincón del mundo, ha encontrado un hogar para su alma y
          el refugio perfecto para vivir cada día con la certeza de que aquí, el
          amor es el lazo que une a todos.
          <br />
        </p>
      </div>
      <div className="color3">
        <h1>Momentos que destacan en su vida!</h1>
      </div>
      <div className="four-column-container">
        <div className="column">
          <ZoomableImage src={require("../../images/n1.jpeg")} alt="Imagen 1" />
        </div>
        <div className="column">
          <ZoomableImage src={require("../../images/n2.jpeg")} alt="Imagen 2" />
        </div>
        <div className="column">
          <ZoomableImage src={require("../../images/n3.jpeg")} alt="Imagen 1" />
        </div>
        <div className="column">
          <ZoomableImage src={require("../../images/n4.jpeg")} alt="Imagen 1" />
        </div>
        <div className="column">
          <ZoomableImage src={require("../../images/n5.jpeg")} alt="Imagen 1" />
        </div>
        <div className="column">
          <ZoomableImage src={require("../../images/n6.jpeg")} alt="Imagen 1" />
        </div>
        <div className="column">
          <ZoomableImage src={require("../../images/n7.jpeg")} alt="Imagen 1" />
        </div>
        <div className="column">
          <ZoomableImage src={require("../../images/n8.jpeg")} alt="Imagen 1" />
        </div>
      </div>

      <div className="video">
        <div className="color4">
          <h1>Momentos tan divertidos que nos comparte!.........</h1>
          <div className="video-container">
            <ReactPlayer
              className="player"
              url={require("../../images/n9.mp4")}
              width="100%"
              controls
              muted
              loop
            />
          </div>
        </div>
      </div>

      <div className="info-container2">
        <div className="info-content2">
          <div className="info-text2">
            <div className="color4">
              <h1>
                "Forjando unidad y armonía como parte del Quórum de los Doce en
                LeBaron."
              </h1>
              <hr />
            </div>
            <p className="color">
              Dentro del Consejo de Ancianos de LeBaron, destaca un grupo
              excepcional conocido como el "Quórum de los Doce". <br />
              <br />
              Entre sus miembros, brilla con luz propia una persona cuyo corazón
              y sabiduría han dejado una huella indeleble en la comunidad:
              Martha Guadalupe Muñoz Hernández.
              <br />
              <br /> Martha, con su visión neutra y su compasión inagotable, ha
              sido un pilar fundamental en el Quórum de los Doce. <br />
              Su presencia en este selecto grupo ha enriquecido la capacidad de
              mediar y resolver situaciones complicadas de cualquier índole.{" "}
              <br />
              <br />
              Como madre ejemplar y miembro destacado del consejo, Martha ha
              demostrado una habilidad única para escuchar con empatía y para
              encontrar soluciones equitativas. <br /> Su dedicación incansable
              al bienestar de LeBaron y su comprensión profunda de las dinámicas
              de la comunidad la convierten en una líder respetada y admirada.
              <br />
              <br /> En cada decisión que toma como miembro del Quórum de los
              Doce, Martha refleja su inquebrantable compromiso con la justicia
              y la armonía en la comunidad. <br />
              Su enfoque neutro y su sabiduría han sido fundamentales para
              mantener la paz y la unidad entre los miembros de LeBaron.
              <br />
              <br /> Dentro y fuera del consejo, Martha es un ejemplo viviente
              de cómo el amor, la perseverancia y la imparcialidad pueden ser la
              clave para superar cualquier obstáculo y para construir un LeBaron
              más fuerte y cohesionado. <br /> <br /> Así, en el corazón de
              LeBaron, el Quórum de los Doce, con la valiosa contribución de
              Martha Guadalupe Muñoz Hernández, sigue escribiendo una historia
              de resiliencia y unidad.
              <br />
              <br /> Su labor inspiradora y trascendental nos recuerda que,
              unidos, podemos superar desafíos y lograr un futuro próspero y
              armónico para todos.
              <br />
            </p>
          </div>
          <div className="info-image2">
            <ZoomableImage
              src={require("../../images/n10.jpeg")}
              alt="Imagen"
            />
          </div>
        </div>
      </div>

      <div className="color5">
        <h1>UN MENSAJE DE MARTHA, PARA TODA LA COMUNIDAD!</h1>
      </div>

      <p className="color">
        Queridos amigos de LeBaron, escuchemos con el corazón abierto: No
        dejemos nunca que la esperanza se desvanezca y que la motivación en la
        autodeterminación nos guíe en cada paso.
        <br />
        <br /> Unidos como una gran familia, dejemos huellas inspiradoras para
        las próximas generaciones, demostrando que juntos podemos alcanzar
        cualquier sueño.
        <br />
        <br /> Que el estandarte de LeBaron siempre ondee alto, recordándonos
        que la fuerza de nuestras familias es el fundamento de nuestros
        triunfos.
        <br />
        <br /> ¡Sigamos adelante, unidos, escribiendo una historia de amor,
        esperanza y pasión por la autodeterminación!
      </p>

      <div className="image3">
        <img src={require("../../images/marmolr11.png")} alt="Imagen" />
      </div>
      {/* <CommentForm /> */}
      <Link to="/brindis">
        <FontAwesomeIcon
          className="icon-login"
          icon={faLeftLong}
          style={{ fontSize: "3em" }}
        />
      </Link>
      <Footer />
    </div>
  );
};

export default Martha;
