import "../styles/brindis.scss";
import Footer from "./Footer";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faChampagneGlasses } from "@fortawesome/free-solid-svg-icons";

const Brindis = () => {
  const brindisDeLaSemana = [
    { nombre: "Martha Muñoz Hernandez", enlace: "/martha" },

  ];

  const futurosBrindis = [
    { nombre: "Adela Lebaron Stubbs", },
    { nombre: "Beberly Paisano Luna", },
    { nombre: "Emily Natasha Lebaron Ray", },
    { nombre: "Elsie Liddiard", },
    { nombre: "Marie Jones", },
    { nombre: "Raymundo Molina Jurado", },
    { nombre: "Rebeca Sanchez Sandoval", },
    { nombre: "Ricardo Paisano", },
  ]

  // listaNombres.sort((a, b) => a.nombre.localeCompare(b.nombre));

  return (
    <div className="brindis-page">
      <Navbar />
      <div className="header">
        <h1>Brindis</h1>
      </div>
      <div className="main">
        <div className="column">
          <ul>
            {brindisDeLaSemana.map((persona, index) => (
              <li key={index}>
                <Link to={persona.enlace}>
                  <FontAwesomeIcon
                    className="icon-login3"
                    icon={faChampagneGlasses}
                  />
                  {persona.nombre}
                </Link>
              </li>
            ))}

            <br />
            FUTUROS BRINDIS
            {futurosBrindis.map((persona, index) => (
              <li key={index}>
                <Link to={persona.enlace}>
                  <FontAwesomeIcon
                    className="icon-login3"
                    icon={faChampagneGlasses}
                  />
                  {persona.nombre}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Brindis;
