import React from "react";
import BackButton from "../../components/BackButton";
import Footer from "../Footer";
import CommentForm from "../../components/CommentForm";
import ZoomableImage from "../../components/ZoomableImage";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./style/adela.scss";

const Adela = () => {
  return (
    <div className="body4">
      <div className="adela-page">
        <BackButton />

        <div className="info-container5">
          <div className="info-content5">
            <div className="info-image2">
              <img src={require("../../images/adela.jpeg")} alt="Imagen" />
            </div>
            <div className="info-text5">
              <h1>
                ""La Huella de Adela: Un Legado de Amor y Cuidado en Lebaron"
              </h1>
              <hr />
              <h1> Los Inicios de una Devoción</h1>
              <p className="text-color5">
                En la tranquila comunidad de Lebaron, rodeada por majestuosas
                montañas y un ambiente sereno, se encuentra Adela Lebaron
                Stubbs, una mujer que ha tejido una historia de servicio y
                devoción que ha dejado una huella imborrable en la vida de
                quienes la rodean.
                <br />
                <br /> Adela, nacida y criada en Lebaron, proviene de una
                familia grande y amorosa.
                <br /> Sus padres, Abel Dayer Lebaron y Valeria Stubbs Ray, le
                dieron una infancia llena de amor y valores sólidos.
                <br />
                <br /> Entre sus numerosos hermanos, Adela aprendió el
                significado de la unidad y la importancia de la familia, valores
                que llevaría consigo a lo largo de su vida.
                <br />
                <br /> Desde una edad temprana, Adela sabía que quería hacer una
                diferencia en su comunidad.
                <br /> Su sueño de convertirse en maestra reflejaba su deseo de
                educar y empoderar a las generaciones futuras.
                <br />
                <br /> Sin embargo, su verdadera inspiración vino de su madre,
                una mujer fuerte y cariñosa que la guió en el camino de la vida.
                <br />
                <br /> Con el tiempo, la influencia de su hermana Ginny, una
                doctora, y la partera Linda Liddard, avivaron la pasión de Adela
                por la atención médica.
                <br />
                <br /> Desde entonces, su sueño de ser doctora se transformó en
                el deseo de convertirse en partera, una decisión que cambiaría
                su vida para siempre.
                <br />
                <br /> Adela es madre de cinco hijos y su familia ocupa un lugar
                central en su corazón.
                <br /> A pesar de su agitada agenda como ama de casa y partera,
                encuentra tiempo para disfrutar de la compañía de sus seres
                queridos y sentirse agradecida por la bendición de vivir en
                México.
                <br />
                <br /> Lo que une a Adela con su comunidad es su compromiso con
                la autodeterminación.
                <br /> Cree que este cambio será beneficioso en términos de
                seguridad y prosperidad.
                <br /> Su profundo amor por su fe y su comunidad brilla en cada
                palabra que comparte.
                <br />
                <br /> La persona más importante en la vida de Adela es su
                esposo, quien ha sido su roca y su apoyo inquebrantable a lo
                largo de los años. <br />
                Juntos, han enfrentado desafíos y celebrado triunfos,
                manteniendo siempre el amor y la unidad en el centro de su
                relación. <br />
                <br />
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="four-column-container4">
            <div className="column4">
              <ZoomableImage
                src={require("../../images/n36.jpeg")}
                alt="Imagen 1"
              />
              <h2>"Partera de Corazón: Donde la Felicidad da a Luz"</h2>
            </div>
            <div className="column4">
              <ZoomableImage
                src={require("../../images/n37.jpeg")}
                alt="Imagen 2"
              />
              <h2>"Sonrisas de Nacimiento: La Felicidad de Ser Partera"</h2>
            </div>
            <div className="column4">
              <ZoomableImage
                src={require("../../images/n38.jpeg")}
                alt="Imagen 1"
              />
              <h2>"Cambiando Vidas con Amor: La Felicidad de Ser Partera"</h2>
            </div>
            <div className="column4">
              <ZoomableImage
                src={require("../../images/n39.jpeg")}
                alt="Imagen 1"
              />
              <h2>"Donde la Felicidad Florece: La Vocación de Ser Partera"</h2>
            </div>
          </div>
        </div>

        <div className="info-container5">
          <div className="info-content5">
            <div className="info-text5">
              <div className="color4">
                <h1> El Camino de una Partera Apasionada</h1>
                <hr />
              </div>
              <p className="text-color3">
                El mejor consejo que ha recibido es un recordatorio constante de
                su madre: "En esta vida no podemos hacer felices a todos".{" "}
                <br />
                <br />
                Para Adela, lo más importante es hacer lo correcto y complacer a
                su Padre Celestial. <br />
                Esto le ha dado la fuerza y la dirección necesarias para superar
                obstáculos y perseguir su pasión.
                <br />
                <br /> Adela admira profundamente a su familia por su capacidad
                para sobresalir a pesar de las dificultades.
                <br /> A pesar de la pérdida de su padre en su juventud, han
                demostrado resiliencia y fortaleza en todo momento.
                <br />
                <br /> Lo que más le gusta de sí misma es su perseverancia, la
                cual la ha llevado a superar desafíos y alcanzar sus metas.{" "}
                <br />
                Su motivación diaria proviene de sus hijos y de su compromiso
                con la juventud de su comunidad.
                <br /> Como miembro activo en la Juventud de Israel, Adela se
                siente inspirada al ver el potencial y la dedicación de los
                jóvenes que serán el futuro de Lebaron.
                <br />
                <br /> La historia de Adela como partera es una saga de
                determinación y pasión.
                <br /> Desde sus primeras experiencias como partera junior hasta
                obtener su licencia después de un arduo proceso de estudio y
                práctica, Adela ha asistido a innumerables partos y ha sido
                testigo de la magia del nacimiento.
                <br />
                <br /> Su consejo final es un recordatorio valioso para todos
                nosotros: "Si Dios te permite entrar en una situación, Él te
                guiará a través de ella y te llevará a un lugar mejor".
                <br />
                <br /> Con estas palabras, Adela nos invita a salir de nuestra
                zona de confort y brindar servicio a los demás, un legado que ha
                vivido y que seguirá inspirando a las generaciones futuras en la
                comunidad de Lebaron.
              </p>
            </div>
            <div className="info-image2">
              <img src={require("../../images/adela.jpeg")} alt="Imagen" />
            </div>
          </div>
        </div>

        <div className="image4">
          <img src={require("../../images/n34.png")} alt="Imagen" />
        </div>
        {/* <CommentForm /> */}
        <Link to="/brindis">
          <FontAwesomeIcon
            className="icon-login"
            icon={faLeftLong}
            style={{ fontSize: "3em" }}
          />
        </Link>
        <Footer />
      </div>
    </div>
  );
};

export default Adela;
