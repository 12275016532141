import React, { useState } from "react";
import "../styles/zoomableimage.scss";

const ZoomableImage = ({ src, alt }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleImageClick = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <img
      src={src}
      alt={alt}
      className={`zoomable-image ${isZoomed ? "zoomed" : ""}`}
      onClick={handleImageClick}
    />
  );
};

export default ZoomableImage;
