import React from "react";
import BackButton from "../../components/BackButton";
import Footer from "../Footer";
import CommentForm from "../../components/CommentForm";
import ZoomableImage from "../../components/ZoomableImage";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./style/beberly9.scss";

const Beberly = () => {
  return (
    <div className="body9">
      <div className="emily-page">
        <BackButton />

        <div className="info-container9">
          <div className="info-content10">
            <div className="info-image2">
              <ZoomableImage
                src={require("../../images/beberly.jpeg")}
                alt="Imagen"
              />
            </div>
            <div className="info-text10">
              <h1>
                "Forjando un legado de amor y sacrificio: La historia de Beberly
                Paisano Luna."
              </h1>
              <hr />
              <h1>
                {" "}
                "El corazón de Lebaron: Un testimonio de unidad y resiliencia."
              </h1>
              <p className="text-color10">
                Beberly Paisano Luna, una figura querida en la comunidad de
                Lebaron, compartió su vida conmigo en una entrevista especial.{" "}
                <br /> <br />
                Su historia es una mezcla de vivencias y sacrificio que forjaron
                una vida llena de significado. <br /> Nacida en el pequeño
                pueblo de San Lorenzo Almecatla, en el estado de Puebla, México,
                Beberly vio la luz del mundo gracias a sus padres, Delfino
                Paisano Álvarez y Julia Luna Carretero. <br /> <br /> Creció en
                una familia numerosa con 14 hermanos, donde cada día era una
                aventura diferente. <br />
                Su infancia estuvo marcada por la responsabilidad temprana,
                viviendo durante tres años con su abuela, Delfina Paisano, a los
                cuatro años. <br /> <br /> Esta experiencia, aunque inusual para
                una niña de tan corta edad, dejó una impresión indeleble en su
                corazón. <br /> El regreso a su hogar y la reunión con su
                familia fueron momentos que Beberly siempre atesoraría. <br />{" "}
                En su pueblo natal, la familia Paisano Luna trabajaba juntos en
                el campo, desde la cosecha de tomates hasta la siembra de
                cebollas. <br /> <br /> Beberly se enamoró de las actividades al
                aire libre, como recoger leña para cocinar y trepar árboles con
                la ayuda de sus hermanos. <br /> Estas experiencias infundieron
                en ella un amor por la naturaleza y un espíritu trabajador que
                la acompañarían a lo largo de su vida.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="four-column-container9">
            <div className="column9">
              <ZoomableImage
                src={require("../../images/beberly2.jpg")}
                alt="Imagen 1"
              />
              <h2> "Forjando lazos inquebrantables"</h2>
              <p className="color6">
                La historia de amor de la familia Paisano Luna.
              </p>
            </div>
            <div className="column9">
              <ZoomableImage
                src={require("../../images/beberly3.jpg")}
                alt="Imagen 1"
              />
              <h2>"Unidos por el amor"</h2>
              <p className="color6">"su eterna conexión con sus hijos."</p>
            </div>{" "}
            <div className="column9">
              <ZoomableImage
                src={require("../../images/beberly4.jpg")}
                alt="Imagen 1"
              />
              <h2>"Capturando momentos eternos"</h2>
              <p className="color7">Beberly y sus hijos en Lebaron.</p>
            </div>{" "}
            <div className="column9">
              <ZoomableImage
                src={require("../../images/beberly5.jpg")}
                alt="Imagen 1"
              />
              <h2> "El legado de Don Paisano"</h2>
              <p className="color7">
                {" "}
                "Un padre, una vida, un amor inolvidable."
              </p>
            </div>
          </div>
        </div>

        <div className="info-container10">
          <div className="info-content10">
            <div className="info-text10">
              <div className="color4">
                <h1>
                  "Sus historias, nuestra inspiración: Los Gueros del Norte, un
                  legado de amor y fortaleza familiar."
                </h1>
                <hr />
              </div>
              <p className="text-color3">
                En la sección del libro titulada "Los Gueros del Norte," se
                desvela una emotiva narración que nos transporta al corazón de
                una familia unida por la fortaleza de sus lazos. <br /> <br />{" "}
                Beberly Paisano Luna y su apreciado padre, conocido
                afectuosamente como Don Paisa, comparten con los lectores una
                crónica única de amor y perseverancia. <br /> A través de sus
                vivencias, este relato trasciende las barreras del tiempo,
                inspirando a todos a valorar la importancia de la familia y el
                profundo afecto que puede perdurar a lo largo de las
                generaciones.
              </p>
            </div>
            <div className="info-image2">
              <ZoomableImage
                src={require("../../images/beberly6.jpg")}
                alt="Imagen"
              />
            </div>
          </div>
        </div>

        <div className="info-container10">
          <div className="info-content10">
            <div className="info-text10">
              <div className="color4">
                <h1>
                  "Beberly Paisano Luna: Enseñándonos a amar, vivir y crecer
                  juntos en Lebaron."
                </h1>
                <hr />
              </div>
              <p className="text-color3">
                A los 13 años, su familia se mudó a Lebaron, un cambio que marcó
                un nuevo capítulo en su vida. Su padre había sido contactado por
                los hermanos Lebaron, quienes vieron potencial en la familia
                Paisano Luna. <br /> <br />
                Fue una sorpresa emocionante para Beberly encontrarse con una
                comunidad de estadounidenses en Lebaron. La amabilidad y el
                apoyo que recibieron al llegar dejaron una impresión profunda en
                su corazón. <br /> En Lebaron, Beberly comenzó a cuidar niños y
                a aprender inglés, una habilidad que se convertiría en una parte
                valiosa de su vida. <br /> <br /> También descubrió su pasión
                por la música cuando su hermana le enseñó a tocar el piano.{" "}
                <br /> Esta habilidad musical se convertiría en una fuente de
                alegría y un medio para dar clases a otros en el futuro. <br />{" "}
                La comunidad de Lebaron no solo les brindó oportunidades
                laborales, sino que también se convirtió en su hogar y refugio.{" "}
                <br /> <br /> Beberly se casó a los 16 años, tuvo hijos y
                experimentó la vida como madre y esposa. <br />A pesar de los
                desafíos que enfrentó, encontró la felicidad en Lebaron y
                apreció profundamente la amabilidad de su gente. La fe desempeñó
                un papel fundamental en su vida. <br /> Beberly y su familia
                eran miembros activos de la Iglesia de Jesucristo de los Santos
                de los Últimos Días. <br /> <br /> Esta fe la ayudó a ser una
                persona más amorosa y paciente, y a encontrar una comunidad que
                la respaldara en su crecimiento espiritual. <br /> Su historia
                es un testimonio de resiliencia, amor familiar y comunidad.{" "}
                <br /> <br />
                Beberly Paisano Luna, una mujer que encontró su lugar en Lebaron
                y compartió su pasión por la música y la vida con aquellos a su
                alrededor. <br /> Su vida es un recordatorio de la importancia
                de la familia, la fe y la comunidad en la construcción de un
                legado significativo.
              </p>
            </div>
            <div className="info-image2">
              <ZoomableImage
                src={require("../../images/beberly7.jpg")}
                alt="Imagen"
              />
            </div>
          </div>
        </div>

        <div className="image4">
          <img src={require("../../images/n62.png")} alt="Imagen" />
        </div>
        {/* <CommentForm /> */}
        <Link to="/brindis">
          <FontAwesomeIcon
            className="icon-login"
            icon={faLeftLong}
            style={{ fontSize: "3em" }}
          />
        </Link>
        <Footer />
      </div>
    </div>
  );
};

export default Beberly;
