import React from "react";
import BackButton from "../../components/BackButton";
import Footer from "../Footer";
import CommentForm from "../../components/CommentForm";
import ZoomableImage from "../../components/ZoomableImage";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./style/merie.scss";

const Merie = () => {
  return (
    <div className="body6">
      <div className="merie-page">
        <BackButton />

        <div className="info-container7">
          <div className="info-content7">
            <div className="info-image2">
              <img src={require("../../images/merie.jpeg")} alt="Imagen" />
            </div>
            <div className="info-text7">
              <h1>"Marie Jones: Un Faro de Inspiración en LeBaron"</h1>
              <hr />
              <h1> "F"orjando Unión y Servicio en LeBaron"</h1>
              <p className="text-color7">
                Marie Jones es una figura icónica en la comunidad de LeBaron, su
                amor y dedicación hacia esta comunidad son incomparables
                <br />
                <br /> A lo largo de los años, Marie se ha convertido en un
                pilar fundamental para LeBaron, un miembro apreciado y admirado
                por todos. Marie nació en Nuevo Casas Grandes, en el seno de una
                familia excepcional.
                <br />
                <br /> Sus padres, Thomas Ossmen Jones y Lorraine Stubbs,
                desempeñaron papeles múltiples en la comunidad, desde la
                agricultura hasta la educación. <br />
                <br />
                Marie es una de quince hermanos por parte de su madre y una de
                cincuenta y siete por parte de su padre.
                <br /> La dedicación de sus padres al servicio comunitario y su
                amor inquebrantable hacia sus hijos dejaron una profunda
                impresión en Marie. Desde su niñez, Marie tenía sueños simples
                pero significativos.
                <br />
                <br /> Anhelaba formar una familia unida y servir a Dios. Su
                modelo a seguir era su hermano Clary, a quien admiraba
                profundamente.
                <br />
                <br /> A medida que crecía, encontraba inspiración en los héroes
                de los relatos del Libro de Mormón, y con el tiempo, sus padres
                se convirtieron en sus héroes más grandes debido a su amor al
                trabajo, al servicio y a Dios.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>

        {/* <div>
          <div className="four-column-container6">
            <div className="column6">
              <ZoomableImage
                src={require("../../images/merie2.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>
            <div className="column6">
              <ZoomableImage
                src={require("../../images/merie2.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>{" "}
            <div className="column6">
              <ZoomableImage
                src={require("../../images/merie2.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>{" "}
            <div className="column6">
              <ZoomableImage
                src={require("../../images/merie2.jpeg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>
          </div>
        </div> */}

        <div className="info-container7">
          <div className="info-content7">
            <div className="info-text7">
              <div className="color4">
                <h1>"Marie Jones: Inspiración que Une a LeBaron"</h1>
                <hr />
              </div>
              <p className="text-color3">
                Marie considera que su rol más importante en la vida es el de
                madre, una profesión que valora sobre todas las demás.
                <br />
                <br /> Tiene tres hijos: Carlos, Cecilia y Rosemarie, así como
                cuatro nietos. Su familia es el centro de su mundo, y lucha
                incansablemente por su bienestar
                <br />
                <br />. Su pasión más grande es el servicio a la comunidad.
                <br /> Marie cree firmemente que la clave para mejorar la
                sociedad radica en la promoción de valores y la equidad.
                <br />
                <br />
                Visualiza un mundo en el que todos amen a Dios y practiquen el
                amor y la empatía.
                <br /> Para Marie, su familia y su comunidad son una extensión
                de su corazón, y su mayor deseo es que, a pesar de las
                diferencias, todos puedan unirse y mantener vivos los principios
                fundamentales de LeBaron.
                <br />
                <br /> La devoción de Marie no se limita a su familia, ya que ha
                contribuido significativamente a la comunidad a lo largo de los
                años.
                <br />
                <br /> Ha sido un pilar en la Iglesia, ha apoyado la música y ha
                participado activamente en proyectos benéficos como Casa de
                Maná, brindando ayuda a quienes más lo necesitan.
                <br />
                <br /> En resumen, Marie Jones es una mujer excepcional, una
                madre ejemplar y una líder comunitaria entregada. Su amor por la
                familia, la comunidad y el servicio son un testimonio vivo de
                que, a través del amor y la dedicación, podemos construir un
                mundo mejor.
                <br />
                <br /> Su legado perdurará en LeBaron y en todos los corazones
                afortunados de conocerla.
              </p>
            </div>
            <div className="info-image2">
              <img src={require("../../images/merie2.jpeg")} alt="Imagen" />
            </div>
          </div>
        </div>

        <div className="image4">
          <img src={require("../../images/n57.png")} alt="Imagen" />
        </div>
        {/* <CommentForm /> */}
        <Link to="/brindis">
          <FontAwesomeIcon
            className="icon-login"
            icon={faLeftLong}
            style={{ fontSize: "3em" }}
          />
        </Link>
        <Footer />
      </div>
    </div>
  );
};

export default Merie;
