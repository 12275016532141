import React from "react";
import { Link } from "react-router-dom";
import "../styles/backbutton.scss"; // Asegúrate de importar tus estilos aquí

const BackButton = () => {
  return (
    <div className="button-container">
      <button className="big-button">
        <Link to="/brindis" onClick={() => window.history.back()}>
          Regresar
        </Link>
      </button>
    </div>
  );
};

export default BackButton;
