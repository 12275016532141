import React from "react";
import BackButton from "../../components/BackButton";
import Footer from "../Footer";
import CommentForm from "../../components/CommentForm";
import ZoomableImage from "../../components/ZoomableImage";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./style/raymundo.scss";

const Raymundo = () => {
  return (
    <div className="body7">
      <div className="raymundo-page">
        <BackButton />

        <div className="info-container8">
          <div className="info-content8">
            <div className="info-image2">
              <img src={require("../../images/mundo.jpg")} alt="Imagen" />
            </div>
            <div className="info-text8">
              <h1>
                "Cantando las notas de la vida: La historia de Raymundo y su
                acordeón mágico."
              </h1>
              <hr />
              <h1> "Mundo: Melodías que Unen Nuestros Espíritus."</h1>
              <p className="text-color8">
                Raymundo, cariñosamente conocido como Mundo. A través de las
                notas mágicas de su acordeón, ha tejido un lazo indeleble de
                alegría que ha tocado los corazones de todos nosotros.
                <br />
                <br /> Raymundo Molina Jurado, un hombre cuyo origen se
                encuentra en el apacible barrio Carvajal de Buenaventura.
                <br />
                <br /> Sus padres, Esteban Molina y Leha Jurado, le
                proporcionaron una infancia repleta de vivencias en la
                naturaleza, donde las montañas eran su patio de juegos.
                <br />
                <br />
                Desde sus primeros años, el alma de Mundo anhelaba convertirse
                en carpintero, pero como suele suceder, el destino tenía otros
                planes. Sin embargo, la vida le tenía preparada una senda
                distinta, una donde el acordeón se convertiría en su fiel
                compañero. <br />
                <br />
                La pasión por la música se apoderó de su espíritu, llevándolo a
                aprender a tocar este instrumento con una tenacidad incansable.
                <br />
                <br /> Cada nota se convirtió en un peldaño en su ascenso hacia
                la maestría.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>

        {/* <div>
          <div className="four-column-container7">
            <div className="column7">
              <ZoomableImage
                src={require("../../images/mundo.jpg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>
            <div className="column7">
              <ZoomableImage
                src={require("../../images/mundo.jpg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color6">LOREM</p>
            </div>{" "}
            <div className="column7">
              <ZoomableImage
                src={require("../../images/mundo.jpg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color7">LOREM</p>
            </div>{" "}
            <div className="column7">
              <ZoomableImage
                src={require("../../images/mundo.jpg")}
                alt="Imagen 1"
              />
              <h2>LOREM</h2>
              <p className="color7">LOREM</p>
            </div>
          </div>
        </div> */}

        <div className="info-container8">
          <div className="info-content8">
            <div className="info-text8">
              <div className="color4">
                <h1>
                  "Mundo y su Acordeón: La Magia de la Música en Nuestras
                  Vidas."
                </h1>
                <hr />
              </div>
              <p className="text-color3">
                Con esfuerzo y determinación, Mundo comenzó a destacar en la
                escena musical regional.
                <br />
                <br /> Viajó a Casas Grandes, donde las calles de Nuevo Casas
                Grandes se convirtieron en su escenario. Fue allí donde empezó a
                darse a conocer, cautivando a quienes tuvieron el privilegio de
                escucharlo.
                <br />
                <br /> A medida que el tiempo avanzaba, otros músicos de Nuevo
                Casas Grandes se percataron de su talento único y lo invitaron a
                unirse a ellos. En esta colaboración, no solo compartieron
                melodías, sino también sabiduría, enriqueciendo aún más su
                crecimiento musical.
                <br />
                <br /> Mundo nos enseña que la música es un regalo divino y su
                mayor motivo de gratitud. A lo largo de sus 58 años en la
                carrera musical, ha dejado una huella imborrable en la
                comunidad.
                <br />
                <br /> Su música es un testimonio vivo de la pasión que uno
                puede encontrar en medio de la vida cotidiana. Este hombre sabio
                reflexiona sobre la sociedad actual y anhela tiempos pasados,
                donde la seguridad y la tranquilidad eran más tangibles. Su
                consejo es sencillo pero valioso: vivir con paciencia, respeto y
                servicialidad hacia los demás.
                <br />
                <br /> Mundo es una inspiración para todos nosotros y sus seres
                queridos lo admiran por la paciencia que irradia, incluso en
                medio de las adversidades.
                <br />
                <br /> Su mayor inspiración proviene de la música, especialmente
                del género norteño personificado por leyendas como Ramón Ayala y
                Cadetes de Linares.
                <br />
                <br /> Mundo nos insta a apreciar la madurez que la vida nos
                regala con el tiempo y las experiencias, una sabiduría que él ha
                adquirido a lo largo de su viaje musical. Un brindis emocionado
                en honor a Mundo, quien, a lo largo de los años, ha regalado
                innumerables momentos de alegría a través de su música, tocando
                nuestros corazones y elevando nuestras almas.
              </p>
            </div>
            <div className="info-image2">
              <img src={require("../../images/mundo.jpg")} alt="Imagen" />
            </div>
          </div>
        </div>

        <div className="image4">
          <img src={require("../../images/n59.png")} alt="Imagen" />
        </div>
        {/* <CommentForm /> */}
        <Link to="/brindis">
          <FontAwesomeIcon
            className="icon-login"
            icon={faLeftLong}
            style={{ fontSize: "3em" }}
          />
        </Link>
        <Footer />
      </div>
    </div>
  );
};

export default Raymundo;
