// import React from "react";
// import "../styles/backgroundvideo.scss";
// import ReactPlayer from "react-player";

// const BackgroundVideo = ({ videoSource }) => {
//   return (
//     <video autoPlay muted loop className="background-video">
//       <source src={videoSource} type="video/mp4" />
//       <ReactPlayer
//         className="player"
//         url={require("../images/copalebaron5.mp4")}
//         width="100%"
//         controls
//         muted
//         loop
//       />
//     </video>
//   );
// };

// export default BackgroundVideo;
import React from "react";
import "../styles/backgroundvideo.scss";
import ReactPlayer from "react-player";

const BackgroundVideo = ({ videoSource }) => {
  return (
    <video autoPlay muted loop className="background-video">
      <source src={videoSource} type="video/mp4" />
      <ReactPlayer
        className="player"
        url={require("../images/copalebaron5.mp4")}
        width="100%"
        controls
        muted
        loop
      />
    </video>
  );
};

export default BackgroundVideo;
