import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/bouncebutton.scss";

const BounceButton = () => {
  return (
    <button className="bounce-button">
      <Link to="/martha" style={{ textDecoration: 'none', color: 'white' }}>
        Esta Semana
      </Link>
    </button>
  );
};

export default BounceButton;

