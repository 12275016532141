import React from "react";
import "../styles/about.scss";
import Footer from "./Footer";
import Navbar from "../components/Navbar";

export default function About() {
  return (
    <div className="about-container">
      <div>
        <Navbar />
      </div>

      <div className="about-title">
        <h1>Nuestra Visión en Brindo Por</h1>
      </div>

      <div className="about-text">
        <p>
          "En Brindo Por, nuestra visión es simple pero poderosa: resaltar y
          celebrar los logros, trayectorias y legados de las personas notables
          de LeBaron.
          <br />
          <br /> Nos enorgullece ser el espacio donde las historias
          excepcionales cobran vida, donde cada paso hacia el éxito y cada
          contribución a la comunidad son reconocidos y aplaudidos.
          <br />
          <br /> Nuestro propósito es que todos puedan sentirse inspirados por
          las narrativas que compartimos, que puedan descubrir y conectarse con
          la riqueza de la historia de LeBaron. <br />
          <br />
          Desde los logros individuales hasta las historias familiares, cada
          página que exploras en Brindo Por está diseñada para brindarte un
          sentido de comunidad y orgullo.
          <br />
          <br /> En cada relato, reconocemos la valentía y la dedicación que hay
          detrás de cada éxito.
          <br />
          <br /> Celebramos la unidad que emerge de estas historias compartidas,
          alentando a cada persona a sentirse parte de algo más grande.
          <br />
          <br /> Juntos, continuamos construyendo el legado de LeBaron, tejiendo
          una trama en la que cada individuo deja su huella y contribuye al
          tejido de nuestra comunidad. <br />
          <br />
          Bienvenidos a Brindo Por, donde honramos, celebramos y unimos."
        </p>
      </div>
      <div className="about-image">
        <img src={require("../images/about.png")} alt="Imagen" />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
